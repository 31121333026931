import React, { Component } from 'react';
import moment from 'moment';
import articles from '../articles.json';

class JaysJourney extends Component {

  renderArticlePreview(article, i) {
    return (
      <article id={`post-${i}`} className="post type-post status-publish format-standard hentry">
        <header>
          <h2 className="blog-title"><a href={`/articles/${article.slug}`} title={article.title} rel="bookmark">{article.title}</a></h2>
          <div className="byline">
            by <span itemProp="author" itemScope="" itemType="http://schema.org/Person">Jason Hatlestad</span> on <time dateTime={article.postDate} itemProp="datePublished">{moment(article.postDate).format('MMM D, YYYY')}</time>
          </div>
        </header>
        <section>
          <p>{article.preview}&hellip;</p>
        </section>
        <span className="link-continue"><a href={`/articles/${article.slug}`} title={article.title} rel="bookmark">Continue Reading</a></span>
        {articles[i + 1] && articles[i + 1].slug &&
          <div className="separator"></div>
        }
      </article>
    );
  }

  render() {
    return (
      <div>
        {articles.map((a, i) => a.slug ? this.renderArticlePreview(a, i) : null)}
      </div>
    );
  }
}

export default JaysJourney;
