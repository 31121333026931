import React, { Component } from 'react';
import aboutJay from '../theme/images/About-Jay.jpg';
import aboutJason from '../theme/images/About-Jason.jpg';

class About extends Component {

  render() {
    return (
      <div>
        <header>
          <h1 className="blog-title">About</h1>
        </header>
        <section>
          <article className="post">
            <p>Select one of the images below to read more on that person.</p>
            <div className="about">
              <div className="person">
                <a href="/about/jay"><img src={aboutJay} alt="Jay, wife (Cindy), and granddaughter (Blakely)" /></a>
                <div className="captionLink">
                  <a href="/about/jay">Jay</a>
                </div>
              </div>
              <div className="person">
                <a href="/about/jason"><img src={aboutJason} alt="Jason, wife (Becca), and daughter (Blakely)" /></a>
                <div className="captionLink">
                  <a href="/about/jason">Jason</a>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

export default About;
