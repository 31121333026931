import React, { Component } from 'react';
import moment from 'moment';
import articles from '../articles.json';

class Article extends Component {

  getArticleIndex() {
    const slug = this.props.match.params.slug;
    return articles.findIndex(a => a.slug === slug);
  }

  renderPrevious(index) {
    if (index > 0) {
      return (
        <div className="nav-previous buttonLink">
          <a href={`/articles/${articles[index - 1].slug}`} rel="prev"><span className="meta-nav">&larr;</span> prev</a>
        </div>
      )
    }
  }

  renderNext(index) {
    if (articles[index + 1] && articles[index + 1].slug) {
      return (
        <div className="nav-previous buttonLink">
          <a href={`/articles/${articles[index + 1].slug}`} rel="next">next <span className="meta-nav">&rarr;</span></a>
        </div>
      )
    }
  }

  render() {
    const articleIndex = this.getArticleIndex();
    const article = articles[articleIndex];
    return (
      <div>
        <article className="post type-post status-publish format-standard hentry category-jays-journey">
          <header>
            {/* <ul class="post-categories">
              <li><a href="/category/jays-journey/" rel="category tag">Jay's Journey</a></li>
            </ul> */}
            <h1 className="blog-title">{article.title}</h1>
            <div className="byline">
              by <span itemProp="author" itemScope="" itemType="http://schema.org/Person">Jason Hatlestad</span> on <time dateTime={article.postDate} itemProp="datePublished">{moment(article.postDate).format('MMM D, YYYY')}</time>
            </div>
          </header>
          <section>
            <div dangerouslySetInnerHTML={{ __html: article.content }} />
            <div className="entry-links"></div>
          </section>
          <footer>
            <nav id="nav-below" className="navigation" role="navigation">
              <div className="nav-previous buttonLink">{this.renderPrevious(articleIndex)}</div>
              <div className="nav-next buttonLink">{this.renderNext(articleIndex)}</div>
            </nav>
          </footer>
        </article>
      </div>
    );
  }
}

export default Article;
