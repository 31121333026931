import React, { Component } from 'react';
import jason from '../theme/images/About-Jason-Page.jpg';

class AboutJason extends Component {

  render() {
    return (
      <div>
        <header>
          <h1 className="blog-title">About Jason</h1>
        </header>
        <section>
          <article className="post">
            <p><img className="aligncenter size-full" src={jason} alt="Jason, wife (Becca), daughter (Blakely)" width="800" height="800" /></p>
            <p>Husband, father, brother, son, Minnesota Vikings worshiper, South Park enthusiast, software engineer, tech guru, craftsman&#8230; are some of the things that might define who I am.</p>
            <p><em>Side note: My name is Jason, yes I am Jay&#8217;s son (jay-son). No my parents did not intend to name me for that reason. It just worked out!</em></p>
            <p>You may also find people calling me &#8216;Face&#8217;. In the family, my sister-in-law has dubbed this nickname for me due to all of the crazy faces that I like to make in front of the camera.</p>
            <p>I have been married to my beautiful wife Becca (and high school sweetheart) since July 2011 who runs her own wellness clinc <a href="https://renuhealthmn.com" target="_blank">RenuHealth</a> in Plymouth.</p>
            <p>In September 2015 we welcomed our daughter Blakely Cecelia to the world, and in October 2017 we welcomed our son Jacob Louis to the world.</p>
            <p>I have been in the technology industry for the past 15+ years, with experience ranging from small companies to large corporations.</p>
            <p>Currently I work as a Software Engineering Manager.</p>
            <p><strong>Living a Healthy Lifestyle</strong></p>
            <p>Before my dad was diagnosed with a Glioblastoma Multiforme brain tumor, I was already starting to focus on educating myself when it comes to diet and exercise, not to mention the environment around us.</p>
            <p>Ever since meeting my wife, I&#8217;ve opened my mind to the idea that there are always other options on the table than what is presented.</p>
            <p>This single piece of information has brought a lot of continued learning about how our society works, how businesses market products, and ultimately that there is a healthier way of living without going overboard.</p>
            <p><strong>Bringing About Real Transformation</strong></p>
            <p>For the weeks that followed my dad&#8217;s diagnosis, I was in a state of denial. Eventually I came to the realization that there was work to be done.</p>
            <p>I decided to start educating myself as much as I could and using my dad&#8217;s blog as a gateway to spreading positivity, and to show people that even when you are kicked in the gut, you can persevere.</p>
            <p>My dad&#8217;s cancer has brought our family closer together, it has opened our eyes to alternative options, it has changed the way I look at life.</p>
            <p><strong>Sharing with the World</strong></p>
            <p>I started Jay&#8217;s Cancer Journey initially as a Caring Bridge blog to keep friends and family informed.</p>
            <p>I have since moved it over to it&#8217;s own website so that I could properly create content to share with the world what we&#8217;ve learned.</p>
            <p>My goal is to help bring information to those who are going through their own journey of cancer, or those who know someone that is.</p>
            <p>On top of that, I hope to help educate everyone about different tools that they can utilize in their daily lives to help enrich their lives and improve their health.</p>
            <p><strong>And I digress&#8230;</strong></p>
            <p>I admit, I am not a professional in the field of science, health, medicine, nutrition, etc. I am just an average guy, who is tired of questioning who or what to believe.</p>
            <p>I am here simply to share my education in a manner that let&#8217;s you make the decision on what you want to believe. I believe that educating yourself is one of the best things you can do for you and your family.</p>
            <p><em><strong>Knowledge is power.</strong></em></p>
            <p>Hopefully you will find something value out of the things that are shared on this site. Maybe someday, you will share something with me that will change my life forever.</p>
            <p>Read on people&#8230;. read on!</p>
          </article>
        </section>
      </div>
    );
  }
}

export default AboutJason;
