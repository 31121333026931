import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import DesignContainer from './layout/DesignContainer';

import Home from './pages/Home';
import About from './pages/About';
import AboutJay from './pages/Jay';
import AboutJason from './pages/Jason';
import Timeline from './pages/Timeline';
import Protocel from './pages/Protocel';
import Article from './pages/Article';
import JaysJourney from './pages/JaysJourney';

class App extends Component {
  render() {
    return (
      <Router>
          <DesignContainer>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/about/jay" component={AboutJay} />
            <Route exact path="/about/jason" component={AboutJason} />
            <Route exact path="/jays-journey" component={JaysJourney} />
            <Route exact path="/timeline" component={Timeline} />
            <Route exact path="/protocel" component={Protocel} />
            <Route exact path="/articles/:slug" component={Article} />
          </DesignContainer>  
      </Router>
    );
  }
}

export default App;
