import React, { Component } from 'react';

class Timeline extends Component {

  render() {
    return (
      <div>
        <header>
          <h1 className="blog-title">Jay&#8217;s Timeline</h1>
        </header>
        <section>
          <article className="post">
            <p>Reading through all of the details post by post can be daunting. That is why I&#8217;ve combined all of the highlights from my dad&#8217;s journey through cancer.</p>
            <p>This includes when he started or stopped various treatments, as well as MRI results for the state of his tumor.</p>
            <p>I will continue to add updates to this timeline as they occur.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2014</strong></span></p>
            <p><strong>April 21, 2014</strong> – Rushed to the hospital via ambulance after collapsing at home. Diagnosed with a brain tumor.</p>
            <p><strong>April 22, 2014 – </strong>Tumor removed via brain surgery.</p>
            <p><strong>April 28, 2014</strong> – Confirmed diagnosis of a Glioblastoma Multiforme (GBM) Stage 4 brain tumor.</p>
            <p><strong>May 7, 2014</strong> – Started taking Protocel 4 times per day</p>
            <p><strong>May 13, 2014</strong> – Started 6-weeks of Radiation and 6-weeks of low-dose Chemo (Temodar). Stopped taking Protocel.</p>
            <p><strong>May 26, 2014</strong> – Learned he has a genetic defect which increases Temodar’s effectiveness. Fatigue starting to set in from Radiation/Chemo.</p>
            <p><strong>June 19, 2014</strong> – Stopped taking low-dose Chemo (week 5) due to blood platelet count being too low.</p>
            <p><strong>June 25, 2014</strong> – Done with Radiation. Done with low-dose Chemo. Resumed taking Protocel 4 times per day. Blood platelet levels down to 47 (normal 150 to 450).</p>
            <p><strong>July 9, 2014</strong> – Platelet levels up to 58.</p>
            <p><strong>July 17, 2014</strong> – Platelet levels up to 92.</p>
            <p><strong>July 23, 2014</strong> – MRI Results in, tumor is growing back.</p>
            <p><strong>August 5, 2014</strong> – MRI Results in, tumor is stable.</p>
            <p><strong>September 10, 2014</strong> – MRI Results in, tumor is stable.</p>
            <p><strong>October 14, 2014</strong> – MRI Results in, tumor is stable (possibly shrinking and breaking apart).</p>
            <p><strong>October 22, 2014</strong> – Decided to go back on Chemo (this time high-dose, 5 days per month for 6 months).</p>
            <p><strong>November 2, 2014</strong> – Started Round 1 of high-dose Chemo. Staying on Protocel this time 4 times per day.</p>
            <p><strong>November 24, 2014</strong> – MRI Results in, tumor has shrunk slightly.</p>
            <p><strong>November 30, 2014</strong> – Started Round 2 of high-dose Chemo. Still on Protocel 4 times per day.</p>
            <p><strong>December 1, 2014</strong> – Contracted Shingles.</p>
            <p><strong>December 16, 2014</strong> – Feeling side effects of chemo.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2015</strong></span></p>
            <p><strong>January 6, 2015</strong> – Holding off on Round 3 (due to Shingles). MRI Results in, tumor has shrunk.</p>
            <p><strong>February 5, 2015</strong> – Started Round 3 of high-dose Chemo (40 days late). Still on Protocel 4 times per day.</p>
            <p><strong>February 17, 2015</strong> – MRI Results in, tumor has shrunk.</p>
            <p><strong>April 11, 2015</strong> – Started Round 4 of high-dose Chemo.</p>
            <p><strong>May 5, 2015</strong> – MRI Results in, tumor has shrunk 10% to 20% from February.</p>
            <p><strong>May 9, 2015</strong> – Started Round 5 of high-dose Chemo.</p>
            <p><strong>June 7, 2015</strong> – Started Round 6 of high-dose Chemo.</p>
            <p><strong>July 7, 2015</strong> – Done with Chemo. Will remain on Protocel 4 times per day.</p>
            <p><strong>August 11, 2015</strong> – MRI Results in, tumor is stable, possibly shrunk.</p>
            <p><strong>November 10, 2015</strong> – MRI Results in, tumor is stable.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2016</strong></span></p>
            <p><strong>February 16, 2016</strong> – MRI Results in, tumor is stable, possibly shrunk.</p>
            <p><strong>May 17, 2016</strong> – MRI Results in, tumor is stable.</p>
            <p><strong>August 16, 2016 – </strong>MRI Results in, tumor is stable.</p>
            <p><strong>November 16, 2016 – </strong>MRI Results in, tumor is stable. Doc admits cancer might instead be scar tissue.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2017</strong></span></p>
            <p><strong>March 15, 2017 – </strong>MRI Results in, no change.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2018</strong></span></p>
            <p><strong>January 15, 2018 – </strong>MRI Results in, no change. Got lax on Protocel protocol.</p>
            <p><strong>April 15, 2018 – </strong>MRI Results in, slight change, along with change in Neurologists.</p>
            <p><strong>May 9, 2018 – </strong>Reviewed MRI Results with new neurologist. Upping MRI frequency due to slightest of changes. Back to normal Protocel regimen.</p>
            <p><strong>June 21, 2018 – </strong>MRI Results in, cancer is back. Two growths have appeared about 1cm in diameter.</p>
            <p><strong>June 21, 2018 – </strong>Began NAET treatments.</p>
            <p><strong>July 2, 2018 – </strong>Radiologist approves targeted radiation.</p>
            <p><strong>August 20, 2018 - </strong>Started Round 1 of high-dose Chemo.</p>
            <p><strong>September 17, 2018 - </strong>Started Round 2 of high-dose Chemo.</p>
            <p><strong>September 28, 2018 - </strong>MRI Results in, tumor is stable, white glow in place of radiation.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2019</strong></span></p>
            <p><strong>April 26, 2019 – </strong>MRI Results in, everything is still stable. Still on Protocel. Still doing rounds of Chemo.</p>
            <p><strong>October 11, 2019 – </strong>MRI Results in, tumor has shrunk 44% since it was at its largest. Still on Protocel. Still doing rounds of Chemo.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2020</strong></span></p>
            <p><strong>March 6, 2020 – </strong>MRI Results in, tumor is stable. Still on Protocel. Still doing rounds of Chemo.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2021</strong></span></p>
            <p><strong>April 21, 2021 – </strong>Hit the 7 year milestone. No updates on tumor outside of being stable. Still on Protocel. Still doing rounds of Chemo.</p>
            <p><strong>October 17, 2021 - </strong>On his final round of chemo. Still on Protocel.</p>
            <p><strong>October 31, 2021 – </strong>Admitted to the hospital with low O2. Tested positive for COVID.</p>
            <p><strong>November 5, 2021 – </strong>Placed on a ventilator in the ICU.</p>
            <p><strong>November 12, 2021 at 5:00pm – </strong>Passed away peacefully at 5pm on the dot. He was removed from the ventilator at 4:10pm. He did not die from cancer.</p>
            <p><span style={{ textDecoration: 'underline' }}><strong>2022</strong></span></p>
            <p><strong>February 28, 2022 - </strong>Autopsy results are in, Jay Hatlestad passed away without any Glioblastoma present. He died cancer free.</p>
          </article>
        </section>
      </div>
    );
  }
}

export default Timeline;
