import React, { Component } from 'react';
import jay from '../theme/images/About-Jay-Page.jpg';
import jaySurgery from '../theme/images/About-Jay-Surgery.jpg';

class AboutJay extends Component {

  render() {
    return (
      <div>
        <header>
          <h1 className="blog-title">About Jay</h1>
        </header>
        <section>
          <article className="post">
            <p><img className="size-full aligncenter" src={jay} alt="Jay, wife (Cindy), granddaughter (Blakely)" /></p>
            <p>Husband, father of two, hockey fanatic, beer connoisseur, wine enthusiast, finance guru… these are a few things that people might consider calling my dad.</p>
            <p>He has been in the finance sector for the past 35+ years, with over 22 of those years held in corporate finance. Since 2005, he has been running his own bookkeeping business, <a href="http://www.rampantlion.biz/" target="_blank" rel="noopener noreferrer">Rampant Lion Bookkeeping</a>, offering services from Controller to QuickBooks Expertise.</p>
            <p>He is a very smart man that is cool natured and easy to get along with.</p>
            <p>He has a love for Gopher Hockey (University of Minnesota), beer, red wine, movies, and history (not particularly in any order, although hockey would probably come first)!</p>
            <p><img className="aligncenter size-full" src={jaySurgery} alt="Jay before going into surgery" width="800" height="612" /></p>
            <p><strong>The Day the Earth Stood Still</strong></p>
            <p>On April 21, 2014, Jay was diagnosed with Glioblastoma Multiforme, a Stage IV type of brain tumor, after collapsing at home in front of his wife and daughter.</p>
            <p>This type of tumor is a very aggressive form of cancer with a low survival rate.</p>
            <p>My dad had most of the tumor removed, went on radiation and chemo, while also taking a lesser known substance called Protocel.</p>
            <p>He completed radiation, but came a week shy of completing his first stint with chemo due to his blood labs not being up to par.</p>
            <p>In July of that same year, we were told that his tumor was growing.</p>
            <p><em><strong>Things were not looking very good.</strong></em></p>
            <p>Fast-forward another month, and his tumor growth had stabilized, which was not expected.</p>
            <p>Over the course of the months to follow, he took a high-dose form of chemo that lasted for 6 months, while continuing to take Protocel.</p>
            <p>Throughout this time his tumor continued to stabilize and shrink.</p>
            <p><strong>Defying All Odds</strong></p>
            <p>Dad has been off chemo since June 2015, and has been only taking Protocel as a supplement for his cancer.</p>
            <p>He is not taking any other form of treatment. His tumor continues to shrink or remain stable (for whatever is left of it).</p>
            <p>His doctors continue to be amazed at how well he is doing despite not being on chemo anymore, as most people in his situation go on and off chemo for the rest of their life.</p>
            <p>The bottom line.</p>
            <p>He has and continues to defy the odds set against him. I am of the belief that Protocel has played a huge role in the matter.</p>
          </article>
        </section>
      </div>
    );
  }
}

export default AboutJay;
