import React, { useState } from 'react';
import logo from '../theme/images/Logo.png';
import bannerTransparent from '../theme/images/Banner-Transparent.png';
import mobileBannerTransparent from '../theme/images/Mobile-Banner-Transparent.png';

function DesignContainer(props) {
  const [showMenu, setShowMenu] = useState(false);

  function renderMenu() {
    if (showMenu) {
      return (
        <div id="MobileNav">
          <div id="MenuClose" onClick={() => setShowMenu(false)}>X</div>
          <nav role="navigation" itemScope="" itemType="http://schema.org/SiteNavigationElement">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about/">About</a></li>
              <li><a href="/jays-journey">Jay's Journey</a></li>
              <li><a href="/timeline/">Timeline</a></li>
              <li><a href="/protocel/">Protocel</a></li>
            </ul>
          </nav>
        </div>
      );
    }
  }

  return (
    <div id="Container">
      {renderMenu()}
      <header id="Header" role="banner">
        <div id="Logo" style={{ zIndex: showMenu ? 0 : 10000 }}>
          <a href="/"><img src={logo} alt="Jay's Cancer Journey" /></a>
        </div>
        <div id="TopNav">
          <div id="RightNav">
            <nav role="navigation" itemScope="" itemType="http://schema.org/SiteNavigationElement">
              <ul className="navBox">
                <li><a href="/about">About</a></li>
              </ul>
            </nav>
          </div>
          <div id="Menu" onClick={() => setShowMenu(true)}>Menu</div>
        </div>
        <div id="Banner">
          <img className="desktop" src={bannerTransparent} alt="Jay's Family" />
          <img className="mobile" src={mobileBannerTransparent} alt="Jay's Family" />
        </div>
        <div id="MidNav">
          <div id="MidBox">
            <nav role="navigation" itemScope="" itemType="http://schema.org/SiteNavigationElement">
              <ul className="navBox">
                <li><a href="/jays-journey/">Jay's Journey</a></li>
                <li><a href="/timeline/">Timeline</a></li>
                <li><a href="/protocel/">Protocel</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <div id="Content">
        <main role="main" itemScope="" itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
          <section id="LeftCol">
            {props.children}
          </section>
          <aside id="RightCol">
            <div className="outer">
              <div className="heading">Who is Jay?</div>
              <p>Welcome! My dad Jay is a normal man who was diagnosed with a deadly form of cancer in 2014.</p>
              <p>He has defied all odds and is living a better life! <a href="/about/jay">More on Jay...</a></p>
            </div>
            <div className="outer">
              <div className="heading">About Jay's Son</div>
              <p>My name is Jason and I'm the author of this blog!</p>
              <p>I'm here to share my dad's story and provide you with the tools to live a better life. <a href="/about/jason">More on me...</a></p>
            </div>
          </aside>
        </main>
        <footer id="Footer" role="contentinfo">
          <div id="Disclaimer">
            <p><strong>Disclaimer:</strong> The statements made here have not been approved by the Food and Drug Administration. These statements are not intended to diagnose, treat or cure or prevent any disease. This notice is required by the Federal Food, Drug and Cosmetic Act.</p>
          </div>
          <div className="separator" style={{ margin: '25px auto 35px auto' }}></div>
          <div id="Copyright">&copy; 2020 Jay's Cancer Journey</div>
        </footer>
      </div>
    </div>
  );
}

export default DesignContainer;
