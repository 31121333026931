import React, { Component } from 'react';
import moment from 'moment';
import articles from '../articles.json';

class Home extends Component {

  renderArticlePreview(article, i) {
    return (
      <article id={`post-${i}`} className="post type-post status-publish format-standard hentry category-jays-journey">
        <header>
          <h2 className="blog-title"><a href={`/articles/${article.slug}`} title={article.title} rel="bookmark">{article.title}</a></h2>
          <div className="byline">
            by <span itemProp="author" itemScope="" itemptype="http://schema.org/Person">Jason Hatlestad</span> on <time dateTime={article.postDate} itemProp="datePublished">{moment(article.postDate).format('MMM D, YYYY')}</time>
          </div>
        </header>
        <section>
          <p>{article.preview}&hellip;</p>
        </section>
        <span className="link-continue"><a href={`/articles/${article.slug}`} title={article.title} rel="bookmark">Continue Reading</a></span>
        {articles[i + 1] && articles[i + 1].slug &&
          <div className="separator"></div>
        }
      </article>
    );
  }

  getMostRecentArticle(articlesToReview) {
    const article = articlesToReview.find(a => a.slug);
    return article;
  }

  render() {
    const myArticles = articles.reverse();
    return (
      <div>
        <header className="page-header">
          <h1 className="blog-title">Jay's Cancer Journey</h1>
          <div className="post">
            <p>In 2014, my dad Jay was diagnosed with Stage 4 Glioblastoma Multiforme (a fast growing and deadly brain tumor). He has continually defied the odds put against him. Follow his journey <a href="/articles/jays-story">from the beginning</a>, check out his <a href="/timeline/">timeline of events</a>, or read the <a href={`/articles/${this.getMostRecentArticle(myArticles).slug}`}>latest update</a>.</p>
          </div>
        </header>
        {myArticles.map((a, i) => a.slug ? this.renderArticlePreview(a, i) : null)}
      </div>
    );
  }
}

export default Home;
