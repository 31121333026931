import React, { Component } from 'react';

class Protocel extends Component {

  render() {
    return (
      <div>
        <header id="post-251" className="post-251 page type-page status-publish hentry">
          <h1 className="blog-title">Protocel</h1>
        </header>
        <section>
          <article className="post">
            <p>If you’ve been keeping up on my dad, I’m sure you’ve been wondering what this Protocel stuff is that he’s been taking.</p>
            <p>I’m going to explain in as simple terms as possible what Protocel is, how it works, who created it, and why you’ve never heard of it until now.</p>
            <p>In case you are unfamiliar with how Protocel relates to my dad Jay, I&#8217;ll give you a quick summary.</p>
            <p>My dad was diagnosed with a Stage IV Glioblastoma Multiforme brain tumor in April 2014.</p>
            <p>Following brain surgery to remove the tumor, my dad started taking Protocel after it had started to grow back. Ever since he started taking it consistently, his tumor has either shrunk or remained stable.</p>
            <p>Eventually the doctors took him off chemo. He continues to take Protocel to this day.</p>
            <p>Now that you understand my dad&#8217;s history, let&#8217;s get on with it.</p>
            <p><strong>What is Protocel Anyway?</strong></p>
            <p>Protocel is a <a href="http://protocel.com/" target="_blank" rel="noopener noreferrer">liquid supplement</a> made up of a proprietary formula of eight ingredients and other trace minerals.</p>
            <p>Marketed as an overall added health supplement, it has been found to be less toxic than consuming one aspirin per day (meaning it essentially is not toxic), has immune system boosting properties, and has an antioxidant rating that is off the charts (<a href="https://www.cantron.com/html/cantron_antioxi_intro.html" target="_blank" rel="noopener noreferrer">ORAC value</a> over 1 million).</p>
            <p>Over the years it has been taken 4 to 5 times per day (depending on the formula) by thousands to help with their journey through cancer.</p>
            <p>The cost is less than $120 per month to use.</p>
            <p>Compare that to the cost of chemo or any other type of drug you might use today, and we are talking a fraction of the cost.</p>
            <p>Great, but what does it do?</p>
            <p>This supplement targets the anaerobic aspect of unhealthy/non-productive cells to help the body eliminate them, leaving healthy cells intact.</p>
            <p><strong>Anaerobic what? You lost me…</strong></p>
            <p>Just as humans need to breath, our cells need to breath using a process called cellular respiration.</p>
            <p>There are two types of cellular respiration, <a href="http://www.diffen.com/difference/Aerobic_Respiration_vs_Anaerobic_Respiration" target="_blank" rel="noopener noreferrer">aerobic and anaerobic</a>, both of which are natural to the body’s proper function.</p>
            <p>In aerobic respiration, cells use oxygen to produce energy, whereas in anaerobic respiration, oxygen is not present so the cells turn to other sources of energy, such as <a href="http://www.cancertruth.net/healthy-cells/#sthash.MjYGMecP.0vIfm7VO.dpbs" target="_blank" rel="noopener noreferrer">glucose</a> (blood sugar).</p>
            <p><strong>Protocel works to destroy unhealthy cells.</strong></p>
            <p>Unhealthy and non-productive cells can occur when components are damaged or malformed (due to a <a href="http://www.cancerfightingstrategies.com/oxygen-and-cancer.html" target="_blank" rel="noopener noreferrer">high toxicity</a> for example) and are unable to consume oxygen properly.</p>
            <p>This is where they turn to anaerobic respiration and continually consume glucose.</p>
            <p>In a way, these damaged cells are trapped into continuously creating energy through the fermentation of glucose.</p>
            <p>In other words, they consume only sugar rather than oxygen to create energy.</p>
            <p><strong>Enter the Warburg Effect</strong></p>
            <p>During the 1930’s, Nobel Prize winning German scientist, Otto Warburg discovered that cancer cells primarily are fueled through the anaerobic process of consuming glucose.</p>
            <p>He believed that all cancer was caused from damaged cells stuck in the anaerobic process.</p>
            <p>This discovery is known as the <a href="https://en.wikipedia.org/wiki/Warburg_hypothesis" target="_blank" rel="noopener noreferrer">Warburg hypothesis</a> (aka Warburg Effect).</p>
            <p>Although it has been proven today that not all cancerous tumors are fueled by glucose like Warburg initially thought, it has been estimated that <a href="http://www.nytimes.com/2016/05/15/magazine/warburg-effect-an-old-idea-revived-starve-cancer-to-death.html" target="_blank" rel="noopener noreferrer">80% of cancers</a> are.</p>
            <p>That is still the vast majority of cancers.</p>
            <p>Remember that Protocel was created to specifically target these rogue cells that are trapped in the anaerobic process. This supplement is able to target these cells by interfering with cellular respiration, but does so in a way by leaving healthy cells intact.</p>
            <p><strong>The Chemist Behind the Curtain</strong></p>
            <p>Jim Sheridan, an American chemist, is the creator of the Protocel formula. It was originally produced as Entelev, renamed later to Cancell, and finally to Protocel.</p>
            <p>He developed this formula over the course of 50 years (starting in the 1930’s, and continuing through to the 1980’s).</p>
            <p>By 1983, Sheridan finalized his formula when he reached a cure rate in mice of 80%. Interestingly enough, as stated prior, it is estimated that 80% of cancers follow the Warburg Effect, which is what Sheridan’s formula is supposedly based on.</p>
            <p>Although there is no real evidence to tie these two numbers together, it is interesting nonetheless.</p>
            <p>Jim has since passed away, but his family has continued to produce his formula to this day.</p>
            <p>For more on the story behind Jim Sheridan and Protocel, check out chapter 9 and 11 from the book, <a href="http://amzn.to/29GTyc4" target="_blank" rel="noopener noreferrer">Outsmart Your Cancer</a>.</p>
            <p><strong>Why haven’t I heard of this before now?</strong></p>
            <p>If you ask around to friends and family, when you mention Protocel, you usually will get a confused look, or one of curiosity.</p>
            <p>That is because this formula is not well known.</p>
            <p>However, if people like my dad are able to defy such odds from its use, then why don’t more people know about it?</p>
            <p>Great question!</p>
            <p>Let me explain.</p>
            <p>Protocel has not been approved by the FDA to be used in the treatment of cancer.</p>
            <p>Although people like my dad have seen success in using Protocel while fighting cancer, because it has not been reviewed and approved by the FDA, this claim cannot legally be made.</p>
            <p>Thus, Protocel is labeled as a supplement that can be used for general overall health, and to aid in the elimination of unhealthy cells.</p>
            <p>Sheridan made various attempts at having the FDA review Protocel (at the time it was labeled Entelev and later Cancell), but the FDA put Jim through the ringer, doing what they could to avoid reviewing it.</p>
            <p>If you’d like to learn about his attempts with the FDA, I highly recommend purchasing <a href="http://amzn.to/29GTyc4" target="_blank" rel="noopener noreferrer">Outsmart Your Cancer</a> and reading chapter 11 to get the full picture of what happened, it is a very interesting read.</p>
            <p>If the FDA will not review it, then scholarly peer reviewed journals will not be written on the subject.</p>
            <p>If these peer reviewed journals are not written, medical doctors will never hear of it, or if they do, they will deny its viability because it is not approved by the FDA.</p>
            <p>&nbsp;</p>
            <p>This unfortunately, is how things work sometimes.</p>
            <p><strong>Where can I buy it?</strong></p>
            <p>Protocel is being sold as a supplement through a couple of online storefronts.</p>
            <ul>
            <li><a href="http://www.renewalandwellness.com/" target="_blank" rel="noopener noreferrer">Renewal &amp; Wellness</a></li>
            <li><a href="http://yourvitamindepot.bizhosting.com/" target="_blank" rel="noopener noreferrer">Vitamin Depot</a></li>
            <li><a href="http://www.webnd.com/" target="_blank" rel="noopener noreferrer">WebND</a></li>
            </ul>
            <p>These distributors have been allowed to sell this product with permission from the Sheridan family.</p>
            <p>There are two formulas, Protocel 23 and Protocel 50. Each version has been found to be effective toward your overall health and at eliminating non-productive cells depending on the type of cancer you have.</p>
            <p>The cost is approximately $80 to $120 per month depending on the formula and size of the bottle you purchase.</p>
            <p><span style={{ textDecoration: 'underline;' }}><strong>Protocel 23</strong></span></p>
            <ul>
            <li>Bladder cancer</li>
            <li>Brain cancer (excluding Glioblastomas)</li>
            <li>Breast cancer</li>
            <li>Kidney cancer (renal cell)</li>
            <li>Leukemia (acute)</li>
            <li>Multiple Myeloma</li>
            <li>Neuroblastoma</li>
            <li>Prostate cancer</li>
            <li>Wilmes tumor</li>
            </ul>
            <p><strong><span style={{ textDecoration: 'underline;' }}>Protocel 50</span></strong></p>
            <ul>
            <li>Adenocarcinoma</li>
            <li>Cervical cancer</li>
            <li>Colon cancer</li>
            <li>Esophageal cancer</li>
            <li>Glioblastoma</li>
            <li>Leukemia (chronic)</li>
            <li>Liver cancer</li>
            <li>Melanoma</li>
            <li>Non-Hodgkin&#8217;s lymphoma</li>
            <li>Ovarian cancer</li>
            <li>Pancreatic cancer</li>
            <li>Sarcomas</li>
            <li>Stomach cancer</li>
            <li>Squamous cell cancer</li>
            <li>Throat cancer</li>
            <li>Uterine cancer</li>
            </ul>
            <p>Protocel has also been found to be affective against other types of diseases and disorders (such as viruses, lupus, mononucleosis, Crohn&#8217;s disease, endometriosis, multiple sclerosis, Parkinson&#8217;s, psoriasis, auto-immune disorders, and more).</p>
            <p>If you plan on taking Protocel, I highly recommend purchasing <a href="http://amzn.to/29GTyc4" target="_blank" rel="noopener noreferrer">Outsmart Your Cancer</a> and read Chapter 12 for details on what to do and not to do when taking Protocel.</p>
            <p><strong>Do you make money off people when they buy Protocel?</strong></p>
            <p>No, absolutely not!</p>
            <p>I am in no way affiliated with the Sheridan family, or any of the distributors of Protocel. I do not make any money when you purchase from these distributors.</p>
            <p>Why not?</p>
            <p>The distributors of Protocel want to keep a tight control over the product so that people don’t improperly market it.</p>
            <p>If people had free reign to market the formula, some would succumb to calling it a cure for cancer (which cannot be said unless the FDA has approved the statement).</p>
            <p>In the end, this could cause the FDA to block the product permanently, which no one wants.</p>
            <p>In addition, I believe in this product so much, that I’m willing to spend my time promoting it to people who may never hear of it otherwise, in exchange for nothing.</p>
            <p>If someone hadn’t taken the time to tell me about this product, my dad might no longer be living.</p>
            <p><strong>I’m worried it will interfere with chemotherapy…</strong></p>
            <p>Chemo works to slow the growth of cells that divide quickly, regardless if they are healthy or unhealthy.</p>
            <p>A common side effect includes a reduced immune system.</p>
            <p>One thing that Protocel does is help boost your immune system slightly. This can help offset the reduction in your immune system that chemo can cause.</p>
            <p>Protocel is a harmless supplement that has not been found to interfere with chemotherapy. My dad Jay is living proof of this.</p>
            <p>Initially he stayed off Protocel while taking chemo.</p>
            <p>When his tumor started to grow back, he went back on Protocel. Any time he had to take chemo, he continued taking Protocel.</p>
            <p>Low and behold, he no longer needs to take chemo and his tumor is practically non-existent.</p>
            <p>If anything, chemo actually interferes with Protocel, but we&#8217;ve found that it is better to take it during chemo then to not.</p>
            <p>Regardless, it is always your call to make, as it is your body.</p>
            <p>If you ask a doctor about it, they will potentially advise you not to take it. They are making this statement based off a lack of information on Protocel, as they do not know what Protocel is.</p>
            <p>Granted, this does not mean you should ignore their advice, they are a doctor after all.</p>
            <p>In the end, you have a choice for what you want to do with your body.</p>
            <p>I am not telling you which choice to make. That choice is yours alone.</p>
            <p><strong>Let’s make one final thing clear.</strong></p>
            <p>Protocel is not a cure for cancer, but some may use it to help them fight cancer.</p>
            <p>It is a supplement to aid in your overall health and in the process, help eliminate non-productive and unhealthy cells.</p>
            <p>If you do use this supplement to aid in your journey through cancer, it may not have any effect because 20% of cancers do not fall under the Warburg Effect.</p>
            <p>Also, it is important to note that Protocel will not work against benign tumors, as these tumors use oxygen to fuel their needs.</p>
            <p>What I can say, is that our family believes Protocel saved my dad’s life. He is living proof of it.</p>
            <p>For how inexpensive it is, why not give it a try?</p>
            <p><em>Some information provided above came from <a href="http://outsmartyourcancer.com/" target="_blank" rel="noopener noreferrer">OutsmartYourCancer.com</a> as well as their <a href="http://amzn.to/29GTyc4" target="_blank" rel="noopener noreferrer">book</a>.</em></p>
          </article>
        </section>
      </div>
    );
  }
}

export default Protocel;
